/* eslint-disable no-console */
const load = require('little-loader');
const Modernizr = require('modernizr');

/** Path to the directory containing polyfills */
const POLYFILL_DIR = '/sites/all/themes/bradysul/build/js';

const resolve = path => `${POLYFILL_DIR}/${path}`;

// -----------------------------------------------------------------------------

/**
 * Object fit polyfill
 */
if (!Modernizr.objectfit) {
  loadPolyfill(resolve('object-fit-polyfill.js'));
}

// -----------------------------------------------------------------------------

/**
 * Loads a polyfill file
 *
 * @param {string} filepath
 *   Path to the file
 *
 * @param {string} message
 */
function loadPolyfill(filepath, message = `Loaded polyfill: ${filepath}`) {
  load(filepath, err => {
    if (err) {
      console.error('Error loading polyfill: %o', err);
      return;
    }

    if (message) {
      console.info(message);
    }
  });
}
